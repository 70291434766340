// Our main CSS
import '../css/app.css'
// layzyload and/or play/pause inline videos when they are in/out of view
const autoplaying_videos = document.querySelectorAll('video[playsinline]')

if (autoplaying_videos && autoplaying_videos.length) {
  import('./modules/inline-videos').then((module) => {
    module.default(autoplaying_videos)
  })
}

if (document.getElementById('accordions')) {
  import('./accordion').then((module) => {
    module.default()
  })
}

if (document.getElementById('contact-form')) {
  import('./contactform').then((module) => {
    module.default()
  })
}

document.querySelector('.js-hamburger').addEventListener('click', function () {
  this.classList.toggle('is-active')
  const isExpanded = this.getAttribute('aria-expanded') === 'true'
  this.setAttribute('aria-expanded', !isExpanded)
  document.querySelector('.js-mobile-nav-menu').classList.toggle('translate-y-0')
})
